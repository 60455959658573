<template>
  <div class="slot-form">
    <form @submit.prevent="onFormSubmit">
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("dictionary.general-data") }}</h3>
        </div>
        <vs-input
          :label-placeholder="$l.get('slot-form.name-field', 'capitalize')"
          :danger="isNameInvalid"
          :danger-text="$l.get('errors.input-required', 'capitalize')"
          v-model="instance.name"
        />
      </vs-card>
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("slot-form.root-field") }}</h3>
        </div>
        <div v-if="instance.root">
          <p>{{ instance.root.id }}</p>
          <h3>{{ instance.root.name }}</h3>
          <br />
          <vs-button
            color="danger"
            type="border"
            style="width: 100% !important"
            icon-pack="feather"
            icon="icon-trash"
            @click="onRemoveRoot"
            >{{ $l.get("dictionary.remove") }}</vs-button
          >
        </div>
        <div v-else>
          <vs-button
            color="primary"
            type="border"
            style="width: 100% !important"
            icon-pack="feather"
            icon="icon-user"
            @click="isUserFindPromptOpen = true"
            >{{ $l.get("dictionary.select") }}</vs-button
          >
        </div>
      </vs-card>
      <input
        type="submit"
        style="position: absolute; left: -9999px; width: 1px; height: 1px;"
        tabindex="-1"
      />
    </form>
    <div style="margin-top: 25px; margin-bottom: 25px; text-align: right">
      <vs-button color="primary" type="filled" @click="onFormSubmit()">
        {{ $l.get("dictionary.submit-new") }}
      </vs-button>
    </div>
    <UserFindPrompt
      :isOpen.sync="isUserFindPromptOpen"
      :onClose="onUserFindPromptClose"
    />
  </div>
</template>

<script>
import UserFindPrompt from "@/application/components/UserFindPrompt";
export default {
  name: "slot-form",
  props: ["instance", "onSubmit"],
  components: { UserFindPrompt },
  data() {
    return {
      isUserFindPromptOpen: false,
      isNameInvalid: false
    };
  },
  mounted() {},
  methods: {
    onFormSubmit() {
      this.isNameInvalid = this.instance.name.length == 0;
      if (this.isNameInvalid) {
        return;
      } else {
        this.onSubmit();
      }
    },
    onUserFindPromptClose(user) {
      this.isUserFindPromptOpen = false;
      if (!user) return;
      this.instance.root = user;
      this.instance.rootId = user.id;
    },
    onRemoveRoot() {
      this.instance.root = null;
      this.instance.rootId = null;
      this.$forceUpdate();
    }
  }
};
</script>

<style lang="scss">
.slot-form .vs-con-input-label {
  width: 100% !important;
  margin-top: 25px;
}

// .slot-form .vs-input {
//   margin: 15px;
// }
</style>
