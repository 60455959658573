<template>
  <div>
    <Loader v-if="isLoading" />
    <div v-else>
      <h3 style="margin-bottom: 25px">{{ $l.get("slot-edit.header") }}</h3>
      <SlotForm
        :instance="data.slot"
        :onSubmit="onSubmit"
        v-if="data && data.slot"
      />
    </div>
  </div>
</template>

<script>
import SubmitFormInteractor from "@/business/slot-edit/submit-form";
import InitSlotEditInteractor from "@/business/slot-edit/init-slot-edit";
import SlotEditScreenController from "@/adapters/controllers/screen-slot-edit";
import SlotForm from "@/application/components/slots/SlotForm";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "slot-edit",
  components: { Loader, SlotForm },
  data() {
    return {
      controller: null,
      interactors: {
        submitForm: null,
        initSlotEdit: null
      },
      data: null,
      isLoading: false
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(SlotEditScreenController);

    //{ INTERACTORS
    this.interactors.initSlotEdit = this.$injector.get(InitSlotEditInteractor);
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    const id = this.$route.params.id;
    this.interactors.initSlotEdit.handle(id);
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmit() {
      this.interactors.submitForm.handle(this.data.slot);
    }
  }
};
</script>

<style lang="scss"></style>
